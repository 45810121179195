
@font-face {
	font-family: "Sailec";
	font-style: normal;
	font-weight: 400;
	src: url("/static/studio/pub/web/budgetsport/webfonts/Sailec/SailecW05-Regular.woff2") format("woff2"), url("/static/studio/pub/web/budgetsport/webfonts/Sailec/SailecW05-Regular.woff") format("woff");
	font-display: swap;
}

@font-face {
	font-family: "Sailec";
	font-style: italic;
	font-weight: 400;
	src: url("/static/studio/pub/web/budgetsport/webfonts/Sailec/SailecW05-RegularItalic.woff2") format("woff2"), url("/static/studio/pub/web/budgetsport/webfonts/Sailec/SailecW05-RegularItalic.woff") format("woff");
	font-display: swap;
}

@font-face {
	font-family: "Sailec";
	font-style: normal;
	font-weight: 700;
	src: url("/static/studio/pub/web/budgetsport/webfonts/Sailec/SailecW05-Bold.woff2") format("woff2"), url("/static/studio/pub/web/budgetsport/webfonts/Sailec/SailecW05-Bold.woff") format("woff");
	font-display: swap;
}

@font-face {
	font-family: "Sailec";
	font-style: italic;
	font-weight: 700;
	src: url("/static/studio/pub/web/budgetsport/webfonts/Sailec/SailecW05-BoldItalic.woff2") format("woff2"), url("/static/studio/pub/web/budgetsport/webfonts/Sailec/SailecW05-BoldItalic.woff") format("woff");
	font-display: swap;
}

@font-face {
	font-family: "Sailec";
	font-style: normal;
	font-weight: 900;
	src: url("/static/studio/pub/web/budgetsport/webfonts/Sailec/SailecW05-Black.woff2") format("woff2"), url("/static/studio/pub/web/budgetsport/webfonts/Sailec/SailecW05-Black.woff") format("woff");
	font-display: swap;
}

@font-face {
	font-family: "Slick";
	src: url("/static/studio/pub/web/budgetsport/webfonts/Slick/slick.woff") format("woff"), url("/static/studio/pub/web/budgetsport/webfonts/Slick/slick.ttf") format("truetype"), url("/static/studio/pub/web/budgetsport/webfonts/Slick/slick.svg#slick") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}


/*
@import url('//fast.fonts.net/t/1.css?apiType=css&projectid=c5b70a02-1d2c-4f52-9c88-5c5ce8b87216');

//Normal
@font-face {
  font-family: 'Univers W01';
  font-style: normal;
  font-weight: 400;
  src: url('/static/studio/pub/web/budgetsport/webfonts/6510377f-160a-4f66-aec0-2b71f05e9736.eot?#iefix');
  src: url('/static/studio/pub/web/budgetsport/webfonts/6510377f-160a-4f66-aec0-2b71f05e9736.eot?#iefix') format('eot'), url('/static/studio/pub/web/budgetsport/webfonts/5c8d59c2-9c85-4a22-88e3-bf3fba01dcd8.woff2') format('woff2'), url('/static/studio/pub/web/budgetsport/webfonts/fc51d03b-0d7d-45df-9415-3c6270c80749.woff') format('woff'), url('/static/studio/pub/web/budgetsport/webfonts/74bdff6d-c5de-42e8-a4d8-11ea14eddb22.ttf') format('truetype'), url('/static/studio/pub/web/budgetsport/webfonts/bd04ae3d-23dc-4975-9967-e21c43e5b59f.svg#bd04ae3d-23dc-4975-9967-e21c43e5b59f') format('svg');
  font-display: swap;
}

//Bold
@font-face {
  font-family: 'Univers W01';
  font-style: normal;
  font-weight: 700;
  src: url('/static/studio/pub/web/budgetsport/webfonts/38dc895e-c366-4a3e-b212-a84a2d07a329.eot?#iefix');
  src: url('/static/studio/pub/web/budgetsport/webfonts/38dc895e-c366-4a3e-b212-a84a2d07a329.eot?#iefix') format('eot'), url('/static/studio/pub/web/budgetsport/webfonts/8a711a0d-3f17-4bfe-887b-6229858332b3.woff2') format('woff2'), url('/static/studio/pub/web/budgetsport/webfonts/05a79c4b-9e9d-46e4-9d4b-610be05371c4.woff') format('woff'), url('/static/studio/pub/web/budgetsport/webfonts/4e00405b-a96d-4972-bdff-2d55e1066c45.ttf') format('truetype'), url('/static/studio/pub/web/budgetsport/webfonts/1e2c0a36-e8bb-4e3b-a4ca-dec00221c06e.svg#1e2c0a36-e8bb-4e3b-a4ca-dec00221c06e') format('svg');
  font-display: swap;
}

//Black
@font-face {
  font-family: 'Univers W01';
  font-style: normal;
  font-weight: 800;
  src: url('/static/studio/pub/web/budgetsport/webfonts/e5fb72ca-12c4-49f5-9253-653474f25a27.eot?#iefix');
  src: url('/static/studio/pub/web/budgetsport/webfonts/e5fb72ca-12c4-49f5-9253-653474f25a27.eot?#iefix') format('eot'), url('/static/studio/pub/web/budgetsport/webfonts/9d846738-13de-43dd-a3f2-ff53924f926c.woff2') format('woff2'), url('/static/studio/pub/web/budgetsport/webfonts/ea880276-dfca-4a2a-a7ce-3d597e85112a.woff') format('woff'), url('/static/studio/pub/web/budgetsport/webfonts/a0361fe2-856b-41d4-a5e3-e87579315974.ttf') format('truetype'), url('/static/studio/pub/web/budgetsport/webfonts/402203c8-9b56-4ded-8eb9-f5cf92a95713.svg#402203c8-9b56-4ded-8eb9-f5cf92a95713') format('svg');
  font-display: swap;
}

//Extrablack
@font-face {
  font-family: 'Univers W01';
  font-style: normal;
  font-weight: 900;
  src: url('/static/studio/pub/web/budgetsport/webfonts/19f50488-ebc6-4208-82c1-f8279c6766c6.eot?#iefix');
  src: url('/static/studio/pub/web/budgetsport/webfonts/19f50488-ebc6-4208-82c1-f8279c6766c6.eot?#iefix') format('eot'), url('/static/studio/pub/web/budgetsport/webfonts/13ffbad7-98b4-44e1-b6de-c0ef3cccd7d7.woff2') format('woff2'), url('/static/studio/pub/web/budgetsport/webfonts/2ca77b3f-158f-41da-a6a1-2de1e93aae30.woff') format('woff'), url('/static/studio/pub/web/budgetsport/webfonts/e95e2b72-5ea6-4133-b8a6-a7e4535dbb00.ttf') format('truetype'), url('/static/studio/pub/web/budgetsport/webfonts/1037f2b1-b528-4b4e-964b-56cf9bd5cbd2.svg#1037f2b1-b528-4b4e-964b-56cf9bd5cbd2') format('svg');
  font-display: swap;
}
*/
